import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';


(function() {

   // relatedTarget

  var forms = document.getElementsByClassName('contactForm')
console.log(forms)
  var successMessage = document.getElementById('successMessage')
  
  var modal = new Modal(document.getElementById('modalForm'), {})

  forms.forEach((form) => {
    form.addEventListener('submit', function (event) {
        const thisForm = this
        console.log(thisForm.email.value)
        event.preventDefault();
        var url = "https://iyfujgydrro566cypxnjn6es6m0sdrsw.lambda-url.us-east-1.on.aws/"; 
        //var url = "https://rvu7c6opqd2p34tzb2xbxnlpse0qchgr.lambda-url.us-east-1.on.aws/"; 

        var xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function() {
            if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4          
               if (xmlhttp.status == 200) {
                  console.log('hide')


                    hideForm(thisForm);
                    setTimeout(function(){hideModal(thisForm)}, 2000);  
                    setTimeout(function(){hideModal(thisForm)}, 2000);  

                  /*if (this.message) {
                    hideForm();
                    setTimeout(hideModal, 2000);  
                  } else {
                    alert('We\'ve saved a spot for you!');
                  }*/


                  form.reset();
               }
               else {
                   alert('Error sending message.');
               }
            }
        };

        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        var payload = 'email='+thisForm.email.value;
        if (thisForm.message && thisForm.message.value) {
            payload += '&message='+thisForm.message.value;
        } 
        //xmlhttp.send('email='+this.email.value+'&message='+this.message.value);
        xmlhttp.send(payload);
    }, false)
  });



  var hideForm = function(el){
    console.log(el)
    el.style.display = "none"; 
    el.previousElementSibling.style.display = "block"; 
  };

  var showForm = function(el){
    var display = 'flex';
    if (el.id == 'modalContactForm') {
        display = 'block';
    }
    el.style.display = display; 
    el.previousElementSibling.style.display = "none"; 
  };  

  var hideModal = function(el){
    if (el.id == 'modalContactForm') {
        modal.hide();
    }
    el.reset();
    setTimeout(function(){showForm(el)}, 1000);
  };



})();

